.home-card {
  text-align: center;
  height: 250px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
}
.home-card > p {
  font-size: 20px;
  color: gray;
}
.home-card > img {
  height: 55px;
}
