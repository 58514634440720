.navbar {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px 20px;
  position: fixed;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 100vw;
  font-size: 18px;
  font-weight: bold;
}

.navbar > img {
  height: 50px;
  margin-left: -100px;
  background-color: aliceblue;
}

.navbar-list {
  display: flex;
  list-style: none;
  justify-content: "space-between";
  width: 500px;
  padding: 0;
}
.navbar-list li {
  cursor: pointer;
}
.navbar-list-second {
  width: 150px;
  padding: 0;
}
.navbar-mobile-container {
  display: none;
  margin-right: 50px;
}

@media screen and (max-width: 900px) {
  .navbar {
    padding: 10px 20px;
  }
  .navbar > img {
    margin-left: 0px;
  }
}
@media screen and (max-width: 700px) {
  .navbar {
    padding: 10px 20px;
    justify-content: space-between;
  }

  .navbar-list {
    display: none;
  }
  .navbar-mobile-container {
    display: inline-block;
  }
}
